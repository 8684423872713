<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(invoice_no)="data">
                <router-link
                  :to="`/transaction-error/detail/${data.item.id}/${data.item.user_guid}`"
                >
                  <u> {{ data.item.invoice_no }}</u>
                </router-link>
              </template>
              <template v-slot:cell(customer_name)="data">
                <router-link
                  :to="`/transaction-error/detail/0/${data.item.user_guid}`"
                >
                  <u> {{ data.item.customer_name }}</u>
                </router-link>
              </template>
              <template v-slot:cell(cancel_transaction_ref)="data">
                <router-link
                  v-if="
                    data.item.cancel_transaction_id != null &&
                    data.item.cancel_transaction_id != data.item.id
                  "
                  :to="`/transaction-error/detail/${data.item.id}/${data.item.user_guid}`"
                >
                  <u> {{ data.item.customer_name }}</u>
                </router-link>
                <span v-else>-</span>
              </template>
              <template v-slot:cell(grand_total)="data">
                <div>{{ data.item.grand_total | numeral('0,0.00') }}</div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    $moment(data.item.created_time)
                      .add(543, 'years')
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    icon="sync-alt"
                    class="pointer"
                    @click="retryBill(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="alertMsg"
      :text="alertText"
      @confirm="confirmReBill"
    />
  </div>
</template>

<script>
import OtherLoading from '@/components/loading/OtherLoading';
import HeaderPanel from '@/components/HeaderPanel';
import Pagination from '@/components/Pagination';
import InputSelect from '@/components/inputs/InputSelect';
import InputText from '@/components/inputs/InputText';
import ModalAlertConfirm from '@/components/modal/alert/ModalAlertConfirm';
import { mapGetters } from 'vuex';

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    ModalAlertConfirm
  },
  name: 'ReportTransaction',
  data() {
    return {
      fields: [
        {
          key: 'invoice_no',
          label: 'หมายเลขใบเสร็จ (Mim/Pos Ref No.)'
        },
        {
          key: 'cancel_transaction_ref',
          label: 'หมายเลขอ้างอิง (Canceled Mim/Pos Ref No.)',
          tdClass: 'min-w-200'
        },
        {
          key: 'customer_name',
          label: 'ชื่อลูกค้า (Name)'
        },
        {
          key: 'telephone',
          label: 'เบอร์โทรศัพท์ (Telephone)'
        },
        {
          key: 'sales_no',
          label: 'รหัสพนักงานขาย (Saleperson ID)'
        },
        {
          key: 'grand_total',
          label: 'ยอดสุทธิ (Net Amount)'
        },
        {
          key: 'created_time',
          label: 'วันที่/เวลา (Date/Time)'
        },
        {
          key: 'action',
          label: 'เหตุผลที่มีปัญหา (Reason)'
        }
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take : 10
      },
      pageOptions: [
        { value: 5, text: '5 / page' },
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      isLoading: true,
      alertMsg: '',
      alertText: '',
      selectedTransactionId: 0
    };
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId'
    })
  },
  created: async function () {
    this.filter.branchId = this.branchId;
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.from_date = payload.fromDate;
      payload.to_date = payload.toDate;
      payload.branch_id = payload.branchId;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/transaction/get_transactions_error`, payload)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit('filterPage', this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit('filterPage', this.filter);
      this.getList();
    },
    async retryBill(transaction) {
      this.selectedTransactionId = transaction.id;
      this.alertMsg = `แก้ไขบิล ${transaction.invoice_no}`;
      this.alertText = `POS REF NO ${transaction.invoice_no} 
              ${
                transaction.ref_invoice_no
                  ? '(Return Ref No : ' + transaction.ref_invoice_no + ')'
                  : ''
              }
              ยืนยันที่จะแก้ไขบิลหรือไม่`;
      this.$refs.modalAlertConfirm.show();
    },
    async confirmReBill() {
      this.isLoading = true;
      let body = {
        id: this.selectedTransactionId
      };
      await this.$store.dispatch('setMainLoading', true);
      await this.axios
        .post(`${this.$baseUrl}/transactionerror/retry`, body)
        .then(data => {
          this.isLoading = false;
          this.$store.dispatch('setMainLoading', false);
          if (data.result == 1) {
            this.successAlert(data.message);
            this.pagination(1);
          } else {
            this.warningAlert(data.message);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table-main {
  table {
    min-width: max-content;
  }
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}
.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.icon {
  font-size: 24px;
  color: #000 !important;
}

::v-deep .header-title {
  gap: 8px;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  flex: 1;
}
</style>
